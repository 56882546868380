<template>
  <a-select
    v-model:value="valueState"
    placeholder="Pilih Kabupaten/Kota"
    allow-clear
    show-search
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    :disabled="disabled"
    :show-arrow="true"
  >
    <template #notFoundContent>
      <template v-if="fetching"> <a-spin size="small" /> Sedang mencari ... </template>
      <template v-else>Tidak ditemukan/Ketikkan Nama Kota/Kabupaten</template>
    </template>
    <a-select-option v-for="d in data" :key="d.id" :label="d[label]">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d[label])"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onUpdated, ref, toRefs, watch, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, merge, keyBy, values } from 'lodash'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    disabled: [Boolean],
    label: {
      default: 'name',
      type: String,
    },
    joinOptions: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)
    const timeout = ref(null)
    const fetching = ref(false)
    const valueState = useVModel(props, 'value')
    const joinOptions = toRefs(props).joinOptions
    const label = toRefs(props).label

    let controller

    const fetchData = q => {
      if (q === null) return data.value
      if (controller) controller.abort()

      controller = new AbortController()
      const { signal } = controller

      return apiClient.get('/api/filter/wilayah-exc-prov', { signal, params: { q } }).then(response => {
        if (!response) {
          if (signal.aborted) return Promise.reject('aborted')
          return []
        }

        let sorted = response.data
        const options = joinOptions.value.filter(o => o.id !== undefined)
        if (options.length > 0) {
          const merged = merge(keyBy(options, 'id'), keyBy(sorted, 'id'))
          sorted = values(merged)
        }
        data.value = sorted.sort((a, b) => {
          return a[label.value].toLowerCase() > b[label.value].toLowerCase() ? 1 : -1
        })

        return Promise.resolve(response)
      })
    }

    const onSearch = value => {
      fetching.value = true
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
      timeout.value = setTimeout(() => {
        findText.value = value
        fetchData(value)
          .then(response => {
            fetching.value = false
          })
          .catch(err => {
            if (err !== 'aborted') {
              fetching.value = false
            }
          })
      }, 300)
    }

    const highlight = value => {
      if (value === null || value === undefined) return

      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    onMounted(() => {
      try {
        if (joinOptions.value.length > 0) {
          data.value = joinOptions.value.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          )
        }
      } catch (error) {
        console.error('failed join options', error)
      }
    })
    const updated = ref(0)
    onUpdated(() => {
      if (props.value !== null && updated.value === 0)
        apiClient.get('/api/wilayah/' + props.value).then(response => {
          data.value.push(response.data)
        })
      updated.value = 1
    })
    if (props.value !== null && props.value !== undefined) {
      apiClient.get('/api/wilayah/' + props.value).then(({ data: _data }) => {
        data.value.push(_data)
      })
    }
    return {
      fetching,
      data,
      valueState,
      findText,
      highlight,
      onSearch,
      // select,
    }
  },
}
</script>

<style></style>
